import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'left-menu',
  templateUrl: './left-menu.component.html',
  styleUrls: ['./left-menu.component.css']
})
export class LeftMenuComponent implements OnInit {
  @Input() menuItems: string;

  constructor() { }

  ngOnInit() {
  }

}
