import { Component, OnInit } from '@angular/core';
import { WebMenuConfig, WebMenuDisplayMode } from '../web-menu/web-menu.component';

declare var $: any;

@Component({
  selector: 'web-main',
  templateUrl: './web-main.component.html',
  styleUrls: ['./web-main.component.css']
})
export class WebMainComponent implements OnInit {
  private mainMenuConfig: WebMenuConfig = {
    DisplayMode: WebMenuDisplayMode.Horizontally,
    ShowMenuName: false,
    NameLabelClasses: "",
    MainMenuClasses: "navbar-nav ml-auto",
    MainMenuItemContainerClasses: "nav-item",
    MainMenuItemClasses: "nav-link js-scroll-trigger",
    DropDownMenuClasses: "",
    DropDownMenuItemContainerClasses: "",
    DropDownMenuItemClasses: ""
  };

  constructor() { }

  ngOnInit() {
    // scroll animation after click on menu item
    $('a.js-scroll-trigger[href*="#"]:not([href="#"])').click(function() {
      if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
        var target = $(this.hash);
        target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
        if (target.length) {
          $("html, body").animate({
            scrollTop: (target.offset().top - 72)
          }, 1000, "easeInOutExpo");
          return false;
        }
      }
    });

    var navbarCollapse = function() {
      if ($("#mainNav").offset().top > 100) {
        $("#mainNav").addClass("navbar-scrolled");
      } else {
        $("#mainNav").removeClass("navbar-scrolled");
      }
    };
    // Collapse now if page is not at top
    navbarCollapse();
    // Collapse the navbar when page is scrolled
    $(window).scroll(navbarCollapse);

  }

  ngOnDestroy() {
  }

}
