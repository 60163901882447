import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { PageService } from '../page.service';
import { PageDto } from '../DtoClasses/PageDto';
import { ActivatedRoute, ParamMap } from '@angular/router';

declare var CKEDITOR: any;
declare var $: any;

@Component({
  selector: 'cms-edit-page',
  templateUrl: './cms-edit-page.component.html',
  styleUrls: ['./cms-edit-page.component.css']
})
export class CmsEditPageComponent implements OnInit {
  private pageDto: PageDto = new PageDto();
  private insertMode: boolean = false;
  private inProgress = false;

  constructor(private pageService: PageService, private route: ActivatedRoute) {
    let pageId = this.route.snapshot.paramMap.get('id');
    if (pageId == null || pageId == undefined) {
      this.insertMode = true;
    } else {
      this.insertMode = false;
      this.inProgress = true;
      this.pageService.GetPage(pageId, (pageDto) => {
        this.inProgress = false;
        this.pageDto = pageDto;
        $('#editor').val(this.pageDto.HtmlText);
      });
    }
  }

  ngOnInit() {
    CKEDITOR.on('instanceReady', function (event) {
      if (!CKEDITOR.instances.editor) return;
      event.editor.resize("100%", $("#editorBox").height());
    });

    $(window).resize(function () {
      if (!CKEDITOR.instances.editor) return;
      CKEDITOR.instances.editor.resize("100%", $("#editorBox").height());
    });

    var thisClass = this;
    $('#editor').ckeditor(function (textarea) {
      $('#editor').val(thisClass.pageDto.HtmlText);
    });
  }

  ngOnDestroy() {
    if (CKEDITOR.instances.editor) CKEDITOR.instances.editor.destroy();
  }

  public SavePage() {
    if (!this.pageDto.Name || this.pageDto.Name.length == 0) {
      alert("Názov stránky nie je zadaný.");
      return;
    }

    this.pageDto.HtmlText = $('#editor').val();
    if (this.insertMode) {
      this.inProgress = true;
      this.pageService.InsertPage(this.pageDto, (result) => {
        this.inProgress = false;
        alert(result.Message);
        if (result.Result) {
          this.insertMode = false;
          this.pageService.GetPage(result.InsertedId, (result) => {
            this.pageDto = result;
          });   
        }
      });
    } else {
      this.inProgress = true;
      this.pageService.UpdatePage(this.pageDto, (result) => {
        this.inProgress = false;
        alert(result.Message);
      });
    }
  }
}
