import { Component, OnInit, ViewChild } from '@angular/core';
import { PageService } from '../page.service';
import { PageDto } from '../DtoClasses/PageDto';
import { Router } from '@angular/router';
import { CmsDialogComponent } from '../cms-dialog/cms-dialog.component';

@Component({
  selector: 'cms-pages',
  templateUrl: './cms-pages.component.html',
  styleUrls: ['./cms-pages.component.css']
})
export class CmsPagesComponent implements OnInit {
  private activeRowId: string = "";
  private pages: Array<PageDto>;
  private editPage: PageDto;

  @ViewChild(CmsDialogComponent) deleteDialog;

  constructor(private pageService: PageService, private router: Router) { }

  ngOnInit() {
    this.Refresh();
  }

  private Refresh() {
    this.pageService.GetPages((result) => {
      this.pages = result;
    });
  }

  private SavePage(pageId: string) {
    this.pageService.DownloadPage(pageId);
  }

  private EditPage(pageId: string) {
    this.router.navigate(['cms/page/', pageId]);
  }

  private DeletePage(pageId: string) {
    this.editPage = this.pages.find(p => p.Id == pageId)
    this.deleteDialog.Show("Naozaj chcete zmazať stránku " + this.editPage.Name + "?");
  }

  private OnClicked(returnValue: boolean) {
    if (returnValue) {
      this.pageService.DeletePage(this.editPage.Id, (result) => {
        if (result.Result) {
          this.Refresh();
        }
        alert(result.Message);
      });
    }
  }
}
