import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'web-scroll-page',
  templateUrl: './web-scroll-page.component.html',
  styleUrls: ['./web-scroll-page.component.css']
})
export class WebScrollPageComponent implements OnInit {
  private x = false;

  constructor(private route: ActivatedRoute, private router: Router) { 
    router.events.subscribe(s => {
      if (s instanceof NavigationEnd) {
        const tree = router.parseUrl(router.url);
        
        if (tree.fragment) {
          const element = document.querySelector("#" + tree.fragment);
          if (element) { element.scrollIntoView(true); }
        }
      }
    }); 
  }

  ngOnInit() {
  }

}
