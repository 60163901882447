import { Component, OnInit, EventEmitter, Output } from '@angular/core';

declare var $: any;

@Component({
  selector: 'cms-dialog',
  templateUrl: './cms-dialog.component.html',
  styleUrls: ['./cms-dialog.component.css']
})
export class CmsDialogComponent implements OnInit {
  private question: string;
  private f: () => void;
  @Output() clicked = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit() {
  }

  public Show(question: string, f: () => void) {
    this.question = question;
    this.f = f;
    $('#deleteUserModalDialog').modal('show');
  }

  private SendValue(returnValue: boolean) {
    if (returnValue && this.f != null) {
      this.f();
    }

    this.clicked.emit(returnValue);
  }
}
