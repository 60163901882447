import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { GalleryImageDto, GalleryFolderDto, GalleryItemsDto } from './gallery/GalleryDataClasses'
import { RequestResult } from './DtoClasses/RequestResult';
import { Settings } from './Global';
import { BaseService } from './BaseService';

@Injectable({
  providedIn: 'root'
})
export class GalleryService extends BaseService {

  constructor(private httpClient: HttpClient) {
    super(httpClient);
  }

  public getGalleryItemsDto(folderPath: string, result: (data: GalleryItemsDto) => void) {
    let params = new HttpParams().set("FolderPath", folderPath);
    this.Get<GalleryItemsDto>(Settings.BaseApiUrl + "GetGalleryItems.php", params, result);
  }

  public uploadImage(file: File, folderPath: string, imageName: string, result: (data: RequestResult) => void) {
    let formData = new FormData();
    formData.append('fileToUpload', file, file.name);
    formData.append('fileType', 'galleryImage');
    formData.append('folderPath', folderPath);

    if (imageName != null && imageName != "") {
      formData.append('fileName', imageName);
    }

    this.Post<RequestResult>(Settings.BaseApiUrl + "upload.php", formData, result);
  }

  public DeleteImage(folderPath: string, imageName: string, result: (data: RequestResult) => void) {
    let params = new HttpParams().set("FolderPath", folderPath)
      .set("ItemName", imageName)
      .set("ItemType", "image");

    this.Get<RequestResult>(Settings.BaseApiUrl + "DeleteGalleryItem.php", params, result);
  }

  public DeleteFolder(folderPath: string, folderName: string, result: (data: RequestResult) => void) {
    let params = new HttpParams().set("FolderPath", folderPath)
      .set("ItemName", folderName)
      .set("ItemType", "folder");

    this.Get<RequestResult>(Settings.BaseApiUrl + "DeleteGalleryItem.php", params, result);
  }

  public CreateFolder(folderPath: string, folderName: string, result: (data: RequestResult) => void) {
    let params = new HttpParams().set("FolderPath", folderPath)
      .set("FolderName", folderName);

    this.Get<RequestResult>(Settings.BaseApiUrl + "CreateGalleryFolder.php", params, result);
  }

  public RenameFolder(folderPath: string, folderOldName: string, folderNewName: string, result: (data: RequestResult) => void) {
    let params = new HttpParams().set("FolderPath", folderPath)
      .set("FolderOldName", folderOldName)
      .set("FolderNewName", folderNewName);
      
    this.Get<RequestResult>(Settings.BaseApiUrl + "RenameFolder.php", params, result);
  }
}
