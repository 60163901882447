import { Component, OnInit } from '@angular/core';
import { UserService } from '../user.service';

declare var $: any;

@Component({
  selector: 'change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  oldPassword: string;
  newPassword: string;
  confirmNewPassword: string;
  inProgress: boolean = false;

  constructor(private userService: UserService) { }

  ngOnInit() {
  }

  public Show() {
    $('#changePasswordModalCenter').modal('show');
  }

  ChangePassword() {
    this.inProgress = true;
    this.userService.ChangePassword(this.oldPassword, this.newPassword, this.confirmNewPassword, (result) => {
      alert(result.Message);
      this.inProgress = false;
      if (result.Result) $('#changePasswordModalCenter').modal('hide');
    });
  }
}
