import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';

import { AppComponent } from './app.component';
import { RouterModule, Routes } from '@angular/router';
import { LeftMenuComponent } from './left-menu/left-menu.component';
import { CmsMenuComponent } from './cms-menu/cms-menu.component';
import { CmsPagesComponent } from './cms-pages/cms-pages.component';
import { LoginComponent } from './login/login.component';
import { UserService } from './user.service';
import { CmsMainComponent } from './cms-main/cms-main.component';
import { FormsModule } from '@angular/forms';
import { AuthguardGuard } from './authguard.guard';
import { HttpClientModule } from '@angular/common/http';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { CmsUsersComponent } from './cms-users/cms-users.component';
import { CmsEditUserComponent } from './cms-edit-user/cms-edit-user.component';
import { CmsDialogComponent } from './cms-dialog/cms-dialog.component';
import { CmsEditPageComponent } from './cms-edit-page/cms-edit-page.component';
import { PageService } from './page.service';
import { CmsMenuItemComponent } from './cms-menu-item/cms-menu-item.component';
import { MenuService } from './menu.service';
import { CmsEditMenuItemComponent } from './cms-edit-menu-item/cms-edit-menu-item.component';
import { WebMainComponent } from './web-main/web-main.component';
import { WebPageComponent } from './web-page/web-page.component';
import { WebMenuComponent } from './web-menu/web-menu.component';
import { WebMenuItemComponent } from './web-menu-item/web-menu-item.component';
import { CmsListDialogComponent } from './cms-list-dialog/cms-list-dialog.component';
import { GalleryComponent } from './gallery/gallery.component';
import { CmsGalleryComponent } from './cms-gallery/cms-gallery.component';
import { CmsGalleryCreateElementComponent } from './cms-gallery-create-element/cms-gallery-create-element.component';
import { SanitizeHtmlPipe } from './sanitize-html.pipe';
import { CmsMenusComponent } from './cms-menus/cms-menus.component';
import { WebGalleryComponent } from './web-gallery/web-gallery.component';
import { WebScrollPageComponent } from './web-scroll-page/web-scroll-page.component';
import { WebPageViewerComponent } from './web-page-viewer/web-page-viewer.component';

const appRoutes: Routes = [
  {
    path: 'cms', component: CmsMainComponent,
    children: [
      { path: '', component: CmsMenusComponent/*, canActivate: [AuthguardGuard]*/ },
      { path: 'menu', component: CmsMenuComponent, canActivate: [AuthguardGuard] },
      { path: 'menu/:id', component: CmsMenuComponent, canActivate: [AuthguardGuard] },
      { path: 'pages', component: CmsPagesComponent, canActivate: [AuthguardGuard] },
      { path: 'page', component: CmsEditPageComponent, canActivate: [AuthguardGuard] },
      { path: 'page/:id', component: CmsEditPageComponent, canActivate: [AuthguardGuard] },
      { path: 'gallery', component: CmsGalleryComponent, canActivate: [AuthguardGuard] },
      /*{ path: 'gallery/:folder', component: CmsEditPageComponent, canActivate: [AuthguardGuard] },*/
      { path: 'users', component: CmsUsersComponent, canActivate: [AuthguardGuard] }
    ]
  },
  { path: 'login', component: LoginComponent },
  {
    path: '', component: WebMainComponent,
    children: [
      { path: '', component: WebScrollPageComponent },
      //{ path: '', component: WebPageComponent, data: { id: 'hlavna-stranka' } },
      { path: 'gallery', component: WebGalleryComponent },
      { path: ':id', component: WebPageViewerComponent }
    ],
  }
  //{ path: '**', component: PageNotFoundComponent }
];

@NgModule({
  declarations: [
    AppComponent,
    CmsMenuComponent,
    CmsPagesComponent,
    LeftMenuComponent,
    LoginComponent,
    CmsMainComponent,
    ChangePasswordComponent,
    CmsUsersComponent,
    CmsEditUserComponent,
    CmsDialogComponent,
    CmsEditPageComponent,
    CmsMenuItemComponent,
    CmsEditMenuItemComponent,
    WebMainComponent,
    WebPageComponent,
    WebMenuComponent,
    WebMenuItemComponent,
    CmsListDialogComponent,
    GalleryComponent,
    CmsGalleryComponent,
    CmsGalleryCreateElementComponent,
    SanitizeHtmlPipe,
    CmsMenusComponent,
    WebGalleryComponent,
    WebScrollPageComponent,
    WebPageViewerComponent
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(
      appRoutes
    ),
    FormsModule,
    HttpClientModule,
    CKEditorModule
  ],
  providers: [UserService, AuthguardGuard, PageService, MenuService],
  bootstrap: [AppComponent]
})

export class AppModule { }
