import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UserService } from '../user.service';
import { UserDto } from '../DtoClasses/UserDto';
import { UserTypeOptionsHelper } from '../Helpers/UserTypeOptionsHelper';

declare var $: any;

@Component({
  selector: 'cms-edit-user',
  templateUrl: './cms-edit-user.component.html',
  styleUrls: ['./cms-edit-user.component.css']
})
export class CmsEditUserComponent implements OnInit {
  private user: UserDto;
  private changePassword: boolean = false;
  private insertMode: boolean = false;
  private title: string;
  private loginName: string;
  private password: string;
  private confirmPassword: string;
  private selectedUserType: string;
  private inProgress: boolean = false;
  private allowedChangePassword = false;

  private userTypeOptions = UserTypeOptionsHelper.GetUserTypeOptions();

  @Output() updated = new EventEmitter();

  constructor(private userService: UserService) { }

  ngOnInit() {
  }

  public InsertUser() {
    $('#editUserModalCenter').modal('show');
    this.user = new UserDto();
    this.password = "";
    this.confirmPassword = "";
    this.title = "Nový užívateľ";
    this.loginName = "";
    this.changePassword = true;
    this.selectedUserType = this.userTypeOptions[1].name;
    this.insertMode = true;
    this.allowedChangePassword = true;
  }

  public EditUser(user: UserDto) {
    $('#editUserModalCenter').modal('show');
    this.title = "Editácia užívateľa";
    this.user = user;
    this.loginName = user.Login;
    this.selectedUserType = UserTypeOptionsHelper.GetUserTypeNameByValue(user.UserType);
    this.insertMode = false;
    this.changePassword = false;
    this.password = "";
    this.confirmPassword = "";
    this.allowedChangePassword = this.user.UserType == 2 && this.userService.LoggedUser.UserType == 1;
  }

  private SaveUser() {
    this.user.Login = this.loginName;
    this.user.UserType = Number(this.userTypeOptions.find(o => o.name == this.selectedUserType).value);
    if (this.changePassword) {
      if (this.password == "" || this.password == undefined) {
        alert("Nie je zadané heslo.");
        return;
      }
      if (this.password != this.confirmPassword) {
        alert("Potvrdené heslo sa nezhoduje s zadaným heslom.");
        return;
      }
      this.user.Password = this.password;
    }
    if (this.insertMode) {
      this.inProgress = true;
      this.userService.InsertUser(this.user, (result) => {
        this.inProgress = false;
        alert(result.Message);
        if (result.Result) this.updated.emit();
      });
    } else {
      this.inProgress = true;
      this.userService.UpdateUser(this.user, (result) => {
        this.inProgress = false;
        alert(result.Message);
        if (result.Result) this.updated.emit();
      });
    }
    $('#editUserModalCenter').modal('hide');
  }
}
