import { Component, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { MenuService } from '../menu.service';
import { MenuItem, MenuItemDto } from '../DtoClasses/MenuDto';
import { CmsListDialogComponent, ListItem } from '../cms-list-dialog/cms-list-dialog.component';
import { PageService } from '../page.service';
import { PageDto } from '../DtoClasses/PageDto';

declare var $: any;

@Component({
  selector: 'cms-edit-menu-item',
  templateUrl: './cms-edit-menu-item.component.html',
  styleUrls: ['./cms-edit-menu-item.component.css']
})
export class CmsEditMenuItemComponent implements OnInit {
  private menuItem: MenuItem = new MenuItem(new MenuItemDto);
  private inProgress: boolean = false;
  @ViewChild(CmsListDialogComponent) pageListDialog;

  constructor(private menuService: MenuService, private pageService: PageService) { }

  ngOnInit() {
  }

  public EditMenuItem(item: MenuItem) {
    $('#editMenuItemModalCenter').modal('show');
    this.menuItem = item;
  }

  private SaveMenuItem() {
    this.menuItem.Dto.Name = this.menuItem.Name;
    this.inProgress = true;
    this.menuService.UpdateMenuItem(this.menuItem.Dto, (result) => {
      this.inProgress = false;
      alert(result.Message);
    });
  }

  private ChoosePage() {
    this.inProgress = true;
    this.pageService.GetPages((pages) => {
      this.inProgress = false;
      let items = new Array<ListItem>();
      pages.forEach(page => items.push(new ListItem(page.Name, page)));
      this.pageListDialog.Show(items);
    });
  }

  private ItemSelected(item: ListItem) {
    this.menuItem.Dto.Url = (item.Item as PageDto).Id;
  }
}
