import { Component, OnInit, ViewChild } from '@angular/core';
import { UserService } from '../user.service';
import { UserDto } from '../DtoClasses/UserDto';
import { CmsDialogComponent } from '../cms-dialog/cms-dialog.component';
import { CmsEditUserComponent } from '../cms-edit-user/cms-edit-user.component';
import { UserTypeOptionsHelper } from '../Helpers/UserTypeOptionsHelper';

declare var $: any;

@Component({
  selector: 'cms-users',
  templateUrl: './cms-users.component.html',
  styleUrls: ['./cms-users.component.css']
})
export class CmsUsersComponent implements OnInit {
  private activeRowId: number = -1;
  private users: Array<UserDto>;
  private userForEdit: UserDto;

  @ViewChild(CmsEditUserComponent) editUserDialog;
  @ViewChild(CmsDialogComponent) deleteDialog;

  constructor(private userService: UserService) { }

  ngOnInit() {
    this.Refresh();
  }

  private GetUserTypeName(value: number) {
    return UserTypeOptionsHelper.GetUserTypeNameByValue(value);
  }

  private Refresh() {
    this.userService.GetUsers((data) => { this.users = data; });
  }

  public EditUser(userId: number) {
    this.userForEdit = this.users.find(user => user.Id == userId);
    this.editUserDialog.EditUser(this.userForEdit);
  }

  public DeleteUser(userId: number) {
    this.userForEdit = this.users.find(user => user.Id == userId);
    this.deleteDialog.Show("Naozaj chcete zmazať užívateľa " + this.userForEdit.Login + "?");
  }

  private OnClicked(returnValue: boolean) {
    if (returnValue) {
      this.userService.DeleteUser(this.userForEdit.Id, (result) => {
        alert(result.Message);
        if (result.Result) this.Refresh();
      });
    }
  }

  private OnUpdated() {
    this.Refresh();
  }

  private AddUser() {
    this.editUserDialog.InsertUser();
  }
}
