import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { WebPageComponent } from '../web-page/web-page.component';

@Component({
  selector: 'web-page-viewer',
  templateUrl: './web-page-viewer.component.html',
  styleUrls: ['./web-page-viewer.component.css']
})
export class WebPageViewerComponent implements OnInit {
  @ViewChild(WebPageComponent) webPage;

  constructor(private route: ActivatedRoute, private router: Router) { 
    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.route.data.subscribe(data => {
          let pageId = this.route.snapshot.paramMap.get('id');

          if (data.id != undefined && pageId == null) {
            pageId = data.id;
          }

          this.webPage.LoadPage(pageId);
        });
      }
    });
  }

  ngOnInit() {
  }

}
