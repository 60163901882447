import { Component, OnInit, Input } from '@angular/core';
import { PageService } from '../page.service';

@Component({
  selector: 'web-page',
  templateUrl: './web-page.component.html',
  styleUrls: ['./web-page.component.css']
})
export class WebPageComponent implements OnInit {
  private htmlContent: string = "";

  @Input() PageId: string = null;

  constructor(private pageService: PageService) {
  }

  public LoadPage(pageId: string) {
    if (pageId != null) {
      this.pageService.GetPage(pageId, (pageDto) => {
        if (pageDto != null) {
          this.htmlContent = pageDto.HtmlText;
        } else {
          this.htmlContent = "<p>Page not found</p>";
        }
      });
    }
  }

  ngOnInit() {
    if (this.PageId != null) {
      this.LoadPage(this.PageId);
    }
  }
}
